import React from 'react';
import { useStaticQuery, graphql, PageProps } from 'gatsby';
import styled from 'styled-components';
import Committee from '../../components/Committee';
import Layout from '../../layouts/Layout';
import SEO from '../../components/SEO';

const Container = styled.div``;

const CommitteesPage: React.FC<PageProps> = function (props) {
  const { location } = props;

  const data = useStaticQuery(graphql`
    query {
      allFile(filter: { sourceInstanceName: { eq: "committee" } }) {
        edges {
          node {
            name
            childMdx {
              frontmatter {
                title
                content
                name
                tags
              }
            }
          }
        }
      }
    }
  `);

  const { edges } = data?.allFile;

  return (
    <Layout>
      <SEO title="Committees" path={location?.pathname} />
      <Container>
        {edges?.map(edge => (
          <Committee key={edge.node.name} committee={edge.node} />
        ))}
      </Container>
    </Layout>
  );
};

export default CommitteesPage;
