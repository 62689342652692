import React from 'react';
import styled from 'styled-components';
import MDX from '../cms/MDX';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid blue;
`;

const Content = styled.div`
  font-size: 2rem;
`;

const Title = styled.span``;

interface Props {
  committee: any;
}

const Committee: React.FC<Props> = function (props) {
  const { committee } = props;

  return (
    <Wrapper>
      <Content>
        <MDX>{committee.childMdx.frontmatter.content}</MDX>
      </Content>
      <Title>{committee.childMdx.frontmatter.title}</Title>
    </Wrapper>
  );
};

export default Committee;
